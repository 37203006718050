<template>
  <FocusBar>
    <template #middle>
      <div class="justify-center items-center hidden md:flex">
        <h2>Showcase builder</h2>
      </div>
    </template>
    <template #before-changes>
      <Btn @click="viewPreview" :link="true" size="lg" :pt="{ root: '!text-pitch-black' }">
        <template #icon>
          <font-awesome-icon :icon="['far', 'eye']" />
        </template>
        <span class="hidden md:block">Web preview</span>
      </Btn>
    </template>
  </FocusBar>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import FocusBar from '../layout/header/FocusBar.vue'

const store = useStore()

const companyId = computed(() => store.state.session.company.company_id)

const publicPageUrl = computed(() => {
  const baseUrl = `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}`
  return `${baseUrl}/pub/company/${companyId.value}`
})

const viewPreview = () => {
  window.open(publicPageUrl.value, '_blank')
}
</script>
